/* <----------- GERAL ----------> */

.container-tela-relatorio {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .navbar-dados-usuario {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  }
  
  .menu-tela-relatorio {
  position: fixed;
  top: 0;
  left: 0;
  /* right: 0; */
  z-index: 3;
  }
  
  .container-tela-relatorio.menu-expanded .main-tela-relatorio {
    display: flex;
    flex-direction: column;
    margin-top: 115px;
    margin-right: 45px;
    margin-left: 220px;
    margin-bottom: 40px;
    padding: 20px;
}

  .container-tela-relatorio.menu-collapsed .main-tela-relatorio {
    display: flex;
    flex-direction: column;
    margin-top: 115px;
    margin-right: 45px;
    margin-left: 115px;
    margin-bottom: 40px;
    padding: 20px;
  }

.row-tela-relatorio {
  display: flex;
  align-items: start;
  margin-bottom: 5px;
}

/* <----------- DROPDOWN ----------> */

  .filter-tela-relatorio {
    margin-right: 20px;
    position: relative;
    margin-bottom: 15px;
    flex: 1 1 200px;
  }

  .filter-label-relatorio {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .filter-select-relatorio {
    height: 52px;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }


  /* <----------- DATA ----------> */

  .date-picker-tela-relatorio {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 55px;
    width: 2000px;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 2;
  }

  .date-picker-tela-relatorio-estatistico {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 55px;
    width: 97,5%;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 0;
  }

  .date-picker-label-tela-relatorio {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 0px 8px 6px 4px;
    font: 10px/100% Roboto, sans-serif;
    width: 50px;
  }

  .date-picker-header-tela-relatorio {
    align-items: start;
    display: flex;
    padding-bottom: 16px;
  }

  .date-picker-content-tela-relatorio {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .calendar-icon-tela-relatorio {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    margin-top: 7px;
    /* margin-left: 35px; */
    cursor: pointer;
  }


  /* <----------- BUTTON ----------> */

  .generate-report-button-container {
    position: absolute;
    top: 520px;
    right: 85px;
}

  .generate-report-button-container-statistic {
    position: absolute;
    top: 450px;
    right: 85px;
  }

  /* <----------- CALENDARIO ----------> */

/* Seletor mais específico para o DatePicker */
.date-picker-tela-relatorio .react-datepicker__day--selected,
.date-picker-tela-relatorio .react-datepicker__day--in-selecting-range,
.date-picker-tela-relatorio .react-datepicker__day--in-range {
    background-color: #bfbfbf !important; /* Cor de fundo dos dias selecionados */
    color: #000000; /* Cor do texto dos dias selecionados */
}

.date-picker-tela-relatorio .react-datepicker__day:hover {
    background-color: #e0e0e0; /* Cor de fundo ao passar o mouse */
    color: #000000; /* Cor do texto ao passar o mouse */
}

/* Ocultar o botão de limpar data */
.react-datepicker__close-icon {
    display: none;
}


/* <----------- INPUT TEXT ----------> */

.filter-tela-relatorio-text {
    position: relative;
    margin-bottom: 15px;
    margin-top: 40px;
    flex: 1 1 200px;
}

.filter-label-relatorio-text {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
}

.report-name-input {
    width: 96%;
    height: 52px;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    border: 2px solid #ccc;
    padding-left: 30px; /* Adiciona padding-left de 30px */
    transition: border-color 0.3s, box-shadow 0.3s;
}

@media (max-width: 1441px) {
  .report-name-input {
      width: 94%;
  }
}

.report-name-input:focus {
    border-color: #ccc; /* Define a cor da borda como #ccc ao focar */
    box-shadow: none; /* Remove qualquer efeito de sombra */
}

.report-name-input::placeholder {
    color: rgba(0, 0, 0, 0.6);
}

/* <----------- CHECKBOX ----------> */

/* Estilo do container do checkbox */
.filter-tela-relatorio input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px; /* Altere o tamanho conforme necessário */
    height: 20px; /* Altere o tamanho conforme necessário */
    border: 2px solid #ccc; /* Cor da borda do checkbox */
    border-radius: 3px; /* Arredondamento dos cantos */
    outline: none;
    cursor: pointer;
    position: relative;
    background-color: white; /* Cor de fundo */
}

.filter-tela-relatorio input[type="checkbox"]:checked {
    background-color: #121926;
    border-color: #121926;
}

.filter-tela-relatorio input[type="checkbox"]:checked::before {
    content: '✔';
    font-size: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.filter-label-relatorio-checkbox {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
}

.centered-checkbox {
    display: flex;
    align-items: center;
}

.save-report-label {
    font-size: 12px; /* Ajuste o tamanho da fonte conforme necessário */
    display: flex;
    align-items: center;
}

/* <----------- PRÉ VISUALIZAÇÃO ----------> */

/* Estilo para o container da pré-visualização */
.preview-container {
  position: relative;
  margin-top: 200px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.preview-container-statistic {
  position: relative;
  margin-top: 270px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Estilo para o cabeçalho da pré-visualização */
.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 12px;
}

/* Estilo para o botão de download */
.download-button {
  background-color: #121926;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 11px;
  margin-top: -8px;
}

.download-button svg {
  margin-right: 5px;
  margin-right: 10px;
}

.buttonClearCache {
  width: 150px;
  height: 45px;
  background: white;
  border: 1px solid #121926;
  margin-right: 10px;
  color: #121926;
  font-weight: bold !important;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.buttonGenerateReport {
  width: 150px;
  height: 45px;
  background-color: #121926;
  color: white;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 12px;
}

.filter-select-relatorio.keyword-dropdown {
  width: 100%;
  border: 1px solid #ccc;
}