
.clipping-container-publico {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 120px;
  margin-left: 80px;
  margin-bottom: 40px;
}

.clipping-header-public {
  display: flex;
  width: 104%;
  gap: 20px;
  justify-content: right;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .clipping-header-public {
    flex-direction: column;
    align-items: flex-start;
  }
}

.clipping-title {
  align-self: start;
  display: flex;
  gap: 8px;
  font-size: 24px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-weight: 600;
  line-height: 135%;
}

.clipping-icon {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 30px;
  margin: auto 0;
}

.clipping-title-text {
  font-family: IBM Plex Sans, sans-serif;
}


.evaluate-button {
  font-feature-settings: "clig" off, "liga" off;
  justify-content: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  font: 500 15px/26px Roboto, sans-serif;
  height: 50px;
  width: 140px;
  cursor: pointer;
}

.evaluate-link {
  text-decoration: none;
  font: 500 15px/26px Roboto, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  color: var(--primary-contrast, #fff);
}

.clipping-content {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104%;
}

.clipping-row {
  gap: 20px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.clipping-details-public {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 74%;
  margin-left: 0px;
}


.clipping-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  flex-grow: 1;
  width: 95%;
  padding: 32px;
}

.blog-site-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  flex-grow: 1;
  width: 1315px;
  padding: 32px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: white;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 2500px) {
  .blog-site-card-public  {
    width: 1930px;
  }
}

@media (max-width: 1921px) {
  .blog-site-card-public {
    width: 1700px;
  }
}

@media (max-width: 1750px) {
  .blog-site-card-public  {
    width: 1505px;
  }
}

@media (max-width: 1537px) {
  .blog-site-card-public {
    width: 1310px;
  }
}

@media (max-width: 1441px) {
  .blog-site-card-public {
    width: 1230px;
  }
}

.clipping-info {
  gap: 20px;
  display: flex;
}


.clipping-meta {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
}


.clipping-summary {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 520px;
}

.blog-site-summary-public{
  width: 750px;
}

@media (max-width: 1441px) {
  .blog-site-summary-public{
    width: 520px;
  }
}


.clipping-heading {
  display: flex;
  gap: 16px;
}

.clipping-image-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.clipping-image {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 48px;
  fill: var(--Foundation-Blue-blue-500, #697586);
}

.clipping-subtitle {
  color: #000;
  align-self: start;
  flex: 1;
  font: 600 16px/150% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}


.clipping-divider {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: var(--divider, rgba(0, 0, 0, 0.12));
  margin-top: 15px;
  height: 1px;
}

.clipping-detail {
  display: flex;
  margin-top: 16px;
  gap: 16px;
  font-size: 14px;
  color: #000;
  line-height: 160%;
}

.clipping-detail-label {
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
}

.clipping-detail-value {
  text-align: right;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  flex: 1;
}

.clipping-actions {
  justify-content: start;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.16px;
  line-height: 138%;
  padding: 36px 0;
  width: 100%;
  margin-top: -20px;
}

@media (max-width: 1800px) {
  .clipping-actions {
    margin-left: 200px;
  }
}


.clipping-action-button-public {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 14px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #121926;
  border-radius: 5px;
}

.clipping-action-button-public .a4-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  background-color: #e0e0e0;
  color: #121212;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.clipping-action-button-public:hover .a4-text {
  opacity: 1;
  visibility: visible;
}


.clipping-action-link-public {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 14px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none; /* Remove underline do link */
  color: inherit; /* Herda a cor do contexto */
  border: 2px solid #121926;
  border-radius: 5px;
}

.clipping-action-link-public .link-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  background-color: #e0e0e0;
  color: #121926;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
}

.clipping-action-link-public:hover .link-text {
  opacity: 1;
  visibility: visible;
}


.clipping-video-public {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 20px;
}


.clipping-video-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 150%;
  width: 100%;
  padding: 32px;
}

.clipping-video-title {
  font-family: IBM Plex Sans, sans-serif;
  margin-top: 0;
}

.clipping-video-placeholder {
  border-radius: 8px;
  background-color: #e0e0e0;
  height: 210px;
}

.clipping-description {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 101%;
}


.clipping-description-title {
  font: 600 18px/135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.clipping-description-divider {
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 15px;
  width: 100%;
}

.clipping-description-text {
  text-align: justify;
  font: 400 16px/24px IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.clipping-footer {
  align-self: end;
  display: flex;
  margin-top: 32px;
  gap: 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.46px;
  line-height: 26px;
  margin-right: -55px;
}

.remove-clipping-button {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius, 4px);
  border: 1px solid rgba(211, 47, 47, 1);
  color: var(--error-main, #d32f2f);
  flex: 1;
  padding: 8px 22px;
  height: 45px;
  width: 120px;
  text-decoration: none;
  cursor: pointer;
}

.edit-clipping-button {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  flex: 1;
  padding: 8px 22px;
  height: 45px;
  width: 200px;
  text-decoration: none;
}

.hr-horizontal {
  margin-top: 20px;
  margin-bottom: -20px;
  width: 520px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.hr-vertical {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1800px) {
  .hr-vertical {
    display: none;
  }
}


/* Estilo para o modal de sobreposição */
.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
display: flex;
justify-content: end;
z-index: 1000; /* Garante que o modal esteja acima de outros elementos */
}

.modal-content {
background-color: white;
padding: 20px;
border-radius: 5px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.avaliado {
background-color: #EDF7ED;
color: #1E4620;
box-shadow: #1E4620;
}


.clipping-video-card-public {
  margin: 0 auto; /* Centraliza horizontalmente */
  max-width: 1100px; /* Define a largura máxima */
  width: 100%; /* Faz com que se ajuste responsivamente */
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: auto;
}

@media (max-width: 1450px) {
  .clipping-video-card {
    margin: 0 auto; /* Centraliza horizontalmente */
    max-width: 1000px; /* Define a largura máxima */
    width: 100%; /* Faz com que se ajuste responsivamente */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: auto;
  }
}

.clipping-detail-inline {
  font-size: 13px;
  text-transform: uppercase;
}

.clipping-header-buttons-public {
  display: flex;
  gap: 10px;
}

@media (max-width: 1000px) {
  .clipping-header-buttons-public {
    margin: 0 auto; /* Centraliza horizontalmente */
    width: 100%; /* Faz com que se ajuste responsivamente */
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    height: auto;
  }
}

  .clipping-thumbnail-public {
  max-height: 30%;
  max-width: 30%;
  object-fit: cover;
  position: absolute;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  }
  

.clipping-thumbnail-public.active {
opacity: 1;
}

.clipping-thumbnails-carousel-public {
  display: flex;
  align-items: start;
  justify-content: center;
  margin-left: 50px;
  width: 40%;
}

.clipping-description-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.clipping-text-content {
  padding-right: 10px; /* Espaço entre o texto e a imagem */
}

.clipping-thumbnails-carousel-public {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clipping-thumbnail-public  {
  height: auto;
  margin-bottom: 10px;
}

.clipping-thumbnail-public.active {
  border: 2px solid #c0c0c0;
}

.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 1000;
}

.modal-content {
background-color: white;
padding: 20px;
border-radius: 5px;
position: relative;
max-width: 90%;
max-height: 90%;
width: auto;
height: auto;
overflow-y: auto;
}

.modal-overlay-visualizacao {
position: fixed;
top: 0;
right: 0; /* Altere de 'left: 0' para 'right: 0' */
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
justify-content: end; /* Alinha o conteúdo à direita */
z-index: 1000;
}

.modal-content-visualizacao {
background-color: white;
padding: 20px;
border-radius: 5px;
position: relative;
max-width: 90%;
height: 100%;
overflow-y: auto;
}

.close-modal {
position: absolute;
top: 15px;
right: 20px;
background: none;
border: none;
font-size: 25px;
cursor: pointer;
border: 1px solid rgba(0, 0, 0, 0.23);
color: rgba(0, 0, 0, 0.7);
}

.thumbnail-navigation {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 10px;
}

.thumbnail-navigation button {
background: none;
border: none;
font-size: 30px;
cursor: pointer;
margin: 0 20px;
}

.thumbnail-image-container {
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
}

.thumbnail-image {
max-width: 100%;
max-height: 100%;
transition: transform 0.3s ease-in-out;
cursor: zoom-in;
}

.clipping-action-button-public.disabled,
.clipping-action-link-public.disabled {
pointer-events: none;
cursor: not-allowed;
opacity: 0.5; /* Opcional, para mostrar que o botão está desabilitado */
}

/* TelaVisualizacao.css */
.highlight {
background-color: yellow; /* Ou qualquer outra cor que você prefira para o destaque */
font-weight: bold;
}

.clipping-summary-public {
display: flex;
flex-grow: 1;
flex-direction: column;
width: 520px;
}

.clipping-video-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download-button-public {
  display: flex;
  align-items: center;
  background-color: #121926;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  margin-top: -20px;
}


.clipping-additional-info {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  width: 101%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  margin-top: 10px;
}

.clipping-title-container {
  margin-bottom: 20px;
  margin-top: -15px;
}

.clipping-additional-title {
  font-size: 20px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.clipping-additional-divider {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin: 0 auto;
  width: 101%;
}

.clipping-details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.clipping-details-container p {
  flex: 1 1 25%;
  margin: 10px 30px 10px 30px;
  font-size: 12px;
  color: #333;
  text-transform: uppercase;
}

.clipping-content-toggle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.clipping-content-toggle button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
  transition: background-color 0.3s ease;
}

.clipping-content-toggle button:last-child {
  border-radius: 0 5px 5px 0;
}

.clipping-content-toggle button:not(:last-child) {
  border-right: none;
}

.clipping-content-toggle button.selected {
  background-color: #121212;
  color: white;
  border-color: #121212;
}

.clipping-content-toggle button:hover {
  background-color: #121212;
  color: white;
}

.clipping-content-toggle button:active {
  background-color: #121212;
}

@media (max-width: 1200px) {
  .clipping-details-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .clipping-details-container p {
    flex: 1 1 100%; /* Cada parágrafo ocupa 100% da largura em telas menores */
    text-align: left;
  }
}



/* <-----------------RESPONSIVE-------------------------> */

/* <-----------------1024-------------------------> */

@media (max-width: 1025px) {
  .clipping-row {
    flex-direction: column; /* Alinha os itens em coluna */
    justify-content: center;
    align-items: center;
  }

  .clipping-summary-public {
    width: 800px;
  }

  .clipping-video-card-public {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 150%;
    width: 665px;
    height: 500px;
    margin-left: -10px;
    padding: 32px;
  }

  .clipping-card-public {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #fff;
    flex-grow: 1;
    width: 810px;
    margin-left: -105px;
    padding: 32px;
  }

  .clipping-actions {
    margin-left: -430px; /* Remova qualquer margem à esquerda */
    margin-top: 300px; /* Adicione uma margem superior para separar dos elementos acima */
  }

  .clipping-thumbnail-public {
    display: none;
  }

  .clipping-thumbnails-carousel {
    display: none;
  }

  .clipping-text-content {
    width: 100% !important;
  }

  .clipping-additional-info {
    width: 135%;
    margin-left: -40px;
  }

  .clipping-additional-title {
    font-size: 12px;
  }

  .clipping-details-container p{
    font-size: 10px;
  }

}

  /* <-----------------834-------------------------> */


  @media (max-width: 835px) {
    .clipping-row {
      flex-direction: column; /* Alinha os itens em coluna */
      justify-content: center;
      align-items: center;
    }
  
    .clipping-summary-public {
      width: 600px;
    }
  
    .clipping-video-card-public {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 150%;
      width: 620px;
      height: 600px;
      margin-left: -10px;
      padding: 32px;
    }
  
    .clipping-card-public {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: #fff;
      flex-grow: 1;
      width: 610px;
      margin-left: -80px;
      padding: 32px;
    }
  
    .clipping-actions {
      margin-left: -300px; /* Remova qualquer margem à esquerda */
      margin-top: 300px; /* Adicione uma margem superior para separar dos elementos acima */
    }
  
    .clipping-thumbnail-public {
      display: none;
    }

    .clipping-thumbnails-carousel {
      display: none;
    }
  
    .clipping-text-content {
      width: 100% !important;
    }
  }

/* <-----------------821-------------------------> */


@media (max-width: 821px) {
    .clipping-row {
      flex-direction: column; /* Alinha os itens em coluna */
      justify-content: center;
      align-items: center;
    }

    .clipping-summary-public {
      width: 600px;
    }

    .clipping-video-card-public {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: #fff;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 150%;
      width: 580px;
      height: 500px;
      margin-left: -5px;
      padding: 32px;
    }

    .clipping-card-public {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: #fff;
      flex-grow: 1;
      width: 605px;
      margin-left: -80px;
      padding: 32px;
    }

    .clipping-actions {
      margin-left: -300px; /* Remova qualquer margem à esquerda */
      margin-top: 300px; /* Adicione uma margem superior para separar dos elementos acima */
    }

    .clipping-thumbnail-public {
      display: none;
    }

    .clipping-thumbnails-carousel {
      display: none;
    }
  
    .clipping-text-content {
      width: 100% !important;
    }
  }


/* <-----------------769-------------------------> */


@media (max-width: 769px) {
  .clipping-row {
    flex-direction: column; /* Alinha os itens em coluna */
    justify-content: center;
    align-items: center;
  }

  .clipping-summary-public {
    width: 550px;
  }

  .clipping-video-card-public {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    line-height: 150%;
    width: 560px;
    height: 500px;
    margin-left: -10px;
    padding: 32px;
  }

  .clipping-card-public {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #fff;
    flex-grow: 1;
    width: 565px;
    margin-left: -75px;
    padding: 32px;
  }

  .clipping-actions {
    margin-left: -300px; /* Remova qualquer margem à esquerda */
    margin-top: 300px; /* Adicione uma margem superior para separar dos elementos acima */
  }

  .clipping-thumbnail-public {
    display: none;
  }

  .clipping-thumbnails-carousel {
    display: none;
  }

  .clipping-text-content {
    width: 100% !important;
  }
  }

/* <-----------------481-------------------------> */

@media (max-width: 481px) {

.clipping-title-text {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 15px;
  width: 350px;
  margin-left: -40px;
}

.hr-horizontal {
  margin-top: 20px;
  margin-bottom: -20px;
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.clipping-summary-public {
  width: 290px;
}

.clipping-description {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 300px;
  margin-left: -40px;
}

.clipping-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  flex-grow: 1;
  width: 280px;
  margin-left: -70px;
  padding: 32px;
}

.clipping-video-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 150%;
  width: 280px;
  height: 500px;
  margin-left: -50px;
  padding: 32px;
}

.clipping-actions {
  margin-left: -160px; /* Remova qualquer margem à esquerda */
  margin-top: 320px; /* Adicione uma margem superior para separar dos elementos acima */
}

.clipping-detail-value {
  text-align: right;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;
  flex: 1 1;
  font-size: 11px;
  }

  .clipping-detail-label {
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 500;
    font-size: 12px;
}

.clipping-subtitle {
  color: #000;
  align-self: start;
  flex: 1 1;
  font: 600 14px / 150% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.clipping-description-title {
  font: 600 16px / 135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.clipping-description-text {
  text-align: justify;
  font: 400 14px / 24px IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.clipping-thumbnail-public {
display: none;
}


.clipping-thumbnails-carousel {
  display: none;
}

.clipping-text-content {
  width: 100% !important;
}

}

/* <-----------------391-------------------------> */

@media (max-width: 391px) {

  .hr-horizontal {
    margin-top: 20px;
    margin-bottom: -20px;
    width: 230px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

.clipping-summary-public {
  width: 270px;
}

.clipping-description {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 300px;
  margin-left: -60px;
}

.clipping-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  flex-grow: 1;
  width: 260px;
  margin-left: -80px;
  padding: 32px;
}

.clipping-video-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 150%;
  width: 260px;
  height: 450px;
  margin-left: -70px;
  padding: 32px;
}

.clipping-thumbnails-carousel {
  display: none;
}

.clipping-text-content {
  width: 100% !important;
}

.clipping-actions {
  margin-left: -150px; /* Remova qualquer margem à esquerda */
  margin-top: 320px; /* Adicione uma margem superior para separar dos elementos acima */
}

.clipping-thumbnail-public {
  display: none;
}
}

/* <-----------------361-------------------------> */

@media (max-width: 361px) {

.clipping-title-text {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  width: 310px;
  margin-left: -45px;
}

.clipping-summary-public {
  width: 270px;
}

.hr-horizontal {
  margin-top: 20px;
  margin-bottom: -20px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.clipping-description {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 280px;
  margin-left: -65px;
}

.clipping-thumbnail-public {
 display: none;
}

.clipping-thumbnails-carousel {
  display: none;
}

.clipping-text-content {
  width: 100% !important;
}
}

/* <-----------------321-------------------------> */

@media (max-width: 321px) {

.clipping-title-text {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 14px;
  width: 280px;
  margin-left: -50px;
}

.clipping-summary-public {
  width: 245px;
}

.hr-horizontal {
  margin-top: 20px;
  margin-bottom: -20px;
  width: 250px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.clipping-description {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 245px;
  margin-left: -65px;
}

.clipping-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  flex-grow: 1;
  width: 225px;
  margin-left: -80px;
  padding: 32px;
}

.clipping-video-card-public {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: #fff;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  line-height: 150%;
  width: 225px;
  height: 400px;
  margin-left: -70px;
  padding: 32px;
}

.clipping-actions {
  margin-left: -120px; /* Remova qualquer margem à esquerda */
  margin-top: 340px; /* Adicione uma margem superior para separar dos elementos acima */
}

.clipping-detail-value {
text-align: right;
font-family: IBM Plex Sans, sans-serif;
font-weight: 400;
flex: 1 1;
font-size: 9px;
}

.clipping-thumbnail-public {
  display: none;
}

.clipping-thumbnails-carousel {
  display: none;
}

.clipping-text-content {
  width: 100% !important;
}

.clipping-additional-info {
  width: 180%;
  margin-left: -50px;
}
}

.clipping-content-carrosel-visualization-screen-public {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: var(--Foundation-Blue-blue-50, #f8fafc);
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  color: #000;
  padding: 25px;
  width: 101%;
  height: 300px;
}

.clipping-images-carousel-public {
text-align: center;
margin-top: 20px;
}

.carousel-container-public {
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
}

.carousel-image-public {
max-width: 500px;
max-height: 300px;
height: auto;
border-radius: 10px;
box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.prev-button-visualization-screen-public {
background-color: white;
color: #121926;
border: 2px solid #121926;
border-radius: 5px;
padding: 10px 15px 10px 16px;
cursor: pointer;
font-size: 16px;
font-weight: bold;
margin-left: 50px;
margin-right: 50px;
}

.prev-button-visualization-screen-public:hover {
background-color: #121926;
color: white;
}

.related-news-section {
  margin-top: 20px;
  padding: 25px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 101%;
}

.related-news-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.related-news-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.related-news-item {
  padding: 10px 0;
  border-bottom: 2px solid #ddd;
}

.related-news-item:last-child {
  border-bottom: none;
}

.related-news-source {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
  font-weight: 500;
}

.related-news-title {
  font-size: 1rem;
  font-weight: bold;
  color: #121926;
  text-decoration: none;
  transition: color 0.3s ease;
}

.related-news-title:hover {
  color: #121926;
  text-decoration: underline;
}
