.video-container {
  position: relative;
  width: 850px;
  height: 385px;
  max-width: 100%;
  margin: auto;
}

video {
  width: 100%;
  height: 385px;
  border-radius: 5px;
}