.container {
  border: 2px solid rgba(245, 245, 245, 1);
  background-color: #fff;
  display: flex;
  max-width: 268px;
  flex-direction: column;
  padding: 16px 0 80px;
  height: 100vh;
  position: fixed;
  width: 59px;
  transition: width 0.5s ease;
  overflow: hidden;
}

.container:hover,
.container.expanded {
  width: 210px;
}

.buttonExpandedMenu {
  margin-top: 20px;
  margin-left: 64px;
  background-color: #121926;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.buttonExpandedMenu img {
  width: 20px;
}

.profile {
  display: flex;
  padding-right: 16px;
  padding-bottom: 10px;
  gap: 10px;
}

.avatar-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.avatar {
  transition: all 0.3s ease;
  width: 35px;
  height: 35px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  margin-left: 12px;
}

.container:hover .avatar,
.container.expanded .avatar {
  width: 190px;
  height: 40px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  margin-left: 12px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  margin: auto 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.container:hover .profile-info,
.container.expanded .profile-info {
  opacity: 1;
}

.edit-link,
.username,
.menu-label {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  font-weight: 600;
  white-space: nowrap;
  line-height: 160%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.menu-item {
  justify-content: center;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  padding: 12px 20px;
  cursor: pointer;
}

.menu-item-content {
  display: flex;
  gap: 8px;
  text-decoration: none;
}

.menu-item:hover,
.menu-item.active {
  background-color: #f0f0f0;
  border-left: 8px solid black;
}

.menu-icon {
  width: 20px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}

.menu-label {
  font-family: IBM Plex Sans, sans-serif;
  flex: 1;
  margin-left: 11px;
}

.username {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%;
  text-transform: uppercase;
}

.edit-link {
  color: var(--text-secondary, rgba(0, 0, 0, 0.60));
  font-family: "IBM Plex Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 22.1px */
  text-decoration-line: underline;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
}

.loading-bar {
  width: 100%;
}