.modal-overlay-share-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content-share-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 30%;
    height: 20%;
  }
  
  .close-modal-share-modal {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.23);
  }
  
  .close-modal-share-modal:hover {
    color: #121926;
  }
  
  .share-options-share-modal {
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .share-button-share-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: white;
    color: #121926;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
  }
  
  .share-button-share-modal:hover {
    background-color: #121926;
    color: white;
  }
  
  .icon-share-modal {
    font-size: 30px;
    margin-bottom: 10px;
  }
  
  .title-container-share-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .title-share-modal {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: #121926;
  }
  