.container-tela-newsletter {
    position: relative;
    display: flex;
    flex-direction: column;
}

.navbar-tela-newsletter {
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.menu-tela-newsletter {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
}

.container-tela-newsletter.menu-expanded .main-tela-newsletter {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-right: 45px;
    margin-left: 220px;
    margin-bottom: 40px;
    padding: 20px;
}

.container-tela-newsletter.menu-collapsed .main-tela-newsletter {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-right: 45px;
    margin-left: 115px;
    margin-bottom: 40px;
    padding: 20px;
}

.main-tela-newsletter h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
}

.separator-tela-newsletter {
    display: flex;
    margin-bottom: 30px;
}

.date-picker-tela-newsletter {
    border-radius: 4px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 55px;
    width: 25%;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 2;
}

.date-picker-label-tela-newsletter {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 0px 8px 9px 4px;
    font: 10px/100% Roboto, sans-serif;
    width: 8%;
}

.date-picker-header-tela-newsletter {
    align-items: start;
    display: flex;
    padding-bottom: 16px;
}

.date-picker-content-tela-newsletter {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.calendar-icon-tela-newsletter {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    margin-top: 7px;
    cursor: pointer;
}

.generate-newsletter-button-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.buttonGenerateNewsletter {
    width: 150px;
    height: 45px;
    background-color: #121926;
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 12px;
    margin-right: 18px;
}

.buttonGenerateNewsletter:hover {
    background-color: white;
    color: #121926;
    border: 2px solid #121926;
}

.time-picker-tela-newsletter {
    border-radius: 4px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 55px;
    width: 150px;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 2;
}

.time-picker-label-tela-newsletter {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 0px 8px 6px 4px;
    font: 10px/100% Roboto, sans-serif;
    margin-top: -12px;
    width: 20%;
}

.selected-time-input-newsletter {
    width: 95%;
    border: none;
    font-size: 14px;
    text-align: start;
    padding: 8px;
    outline: none;
    cursor: text;
}

.input-field-tela-newsletter {
    border-radius: 4px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 55px;
    width: 300px;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 2;
}

.input-label-tela-newsletter {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 0px 8px 6px 4px;
    font: 10px/100% Roboto, sans-serif;
    margin-top: -12px;
    width: 8%;
}

.input-text-tela-newsletter {
    width: 95%;
    border: none;
    font-size: 14px;
    padding: 8px;
    outline: none;
}

.iframe-container-newsletter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.email-send-container-newsletter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.input-field-tela-newsletter-mail {
    border-radius: 4px;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 45px;
    max-width: 400px;
    min-width: 250px;
    flex: 0.6 1 1px;
    margin-right: 20px;
    z-index: 2;
}

.buttonSendNewsletter {
    width: 150px;
    height: 45px;
    background-color: #121926;
    color: white;
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    font-size: 12px;
    margin-top: 18px;
}

.buttonSendNewsletter:hover {
    background-color: white;
    color: #121926;
    border: 2px solid #121926;
}

.input-label-tela-newsletter-mail {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 0px 8px 6px 4px;
    font: 10px / 100% Roboto, sans-serif;
    margin-top: -12px;
    width: 30%;
}


