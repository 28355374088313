.modal-overlay-change-category {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content-change-category {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    width: 50%;
    height: 35%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title-change-category {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: #121926;
    display: flex;
    margin-bottom: 30px;
}

.filter-container-change-category {
    margin-right: 20px;
    position: relative;
    margin-bottom: 15px;
  }

.filter-label-change-category{
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
}

.filter-select-change-category {
    width: 22vw;
    height: 52px;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.modal-actions-change-category {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 100px;
}

.container-change-category {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cancel-button-change-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #121926;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
    margin-right: 10px;
}

.cancel-button-change-category:hover {
    background-color: #121926;
    color: white;
}

.save-button-change-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #121926;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
    margin-right: 20px;
}

.save-button-change-category:hover {
    background-color: white;
    color: #121926;
}