.relatorio-estatistico-container {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
    text-align: center;
}

.relatorio-estatistico-table {
    width: 50%;
    border-collapse: collapse;
    margin-top: 20px;
}

.relatorio-estatistico-table th, 
.relatorio-estatistico-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.relatorio-estatistico-table input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
}

.download-button-statistics {
    display: none;
}

.page-break-statistic {
    page-break-before: always;
    display: block;
    height: 10px;
    width: 105%;
    background-color: #cfcece;
    margin-left: -20px;
}

.pdf-hidden {
    display: none !important;
}

.report-cover-statistic, 
.report-content-statistic {
    height: 1144px;
}

.report-cover-statistic h1 {
    font-size: 60px;
    color: #393939;
}

.report-cover-statistic p {
    font-size: 20px;
    color: #6d6c6c;
    margin-top: -10px;
}

.brand-logo-statistics {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 300px;
}

.clipping-info-statistics {
    padding: 15px;
    font-size: 16px;
    margin-top: 30px;
    display: flex;
}

.clipping-info-statistics h3 {
    margin-bottom: 8px;
    margin-right: 120px;
    font-size: 18px;
    color: #333;
}

.clipping-info-statistics ul {
    list-style-type: none;
    padding: 0;
}

.clipping-info-statistics li {
    margin-bottom: 5px;
    font-size: 16px;
    color: #555;
}

.media-types-container-statistics {
    display: flex;
    justify-content: center;
    align-items: center;
}

