  .search-container {
    display: flex;
    max-width: 344px;
    gap: 16px;
    white-space: nowrap;
  }

  .date-picker {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    flex-direction: column;
    font-weight: 400;
    letter-spacing: 0.15px;
    justify-content: center;
    padding: 0 8px;
    height: 42px;
    width: 200px;
  }

  .date-picker-header {
    align-items: start;
    display: flex;
    padding-bottom: 16px;
    cursor: pointer;
  }

  .date-picker-content {
    display: flex;
    flex-direction: column;
  }

  .date-picker-label {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    padding: 5px 8px 0 4px;
    font: 10px/100% Roboto, sans-serif;
    width: 20px;
  }

  .selected-date {
    color: rgba(0, 0, 0, 0.87);
    margin-top: 5px;
    margin-left: 4px;
    font: 12.5px/150% Roboto, sans-serif;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .calendar-icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 20px;
    margin-top: 4px;
    margin-left: 5px;
  }

  .search-button {
    justify-content: center;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    background-color: #121926;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.46px;
    padding: 8px 22px;
    font-weight: 500;
    font-size: 10px;
    font-family: Roboto, sans-serif;;
    width: 125px;
    cursor: pointer;
  }

  .selected-date-input {
    margin-top: 7px;
    width: 80px;
    border: none;
    outline: none;
    font-size: 12px;
  }
  

/* Estilo para o calendário */
.react-datepicker {
  font-family: Arial, sans-serif;
}

.react-datepicker__header {
  background-color: #ccc;
  border-bottom: 1px solid #ddd;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #121926;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #ccc;
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ccc;
}

.react-datepicker__day--today {
  border: 1px solid #121926;
}

/* Adiciona o estilo de hover para os dias */
.react-datepicker__day:hover {
  background-color: #121926;
  color: white;
}

/* Estilos personalizados para os botões de navegação do DatePicker */
.react-datepicker__navigation {
  top: 5px;
  line-height: 1.7em;
  opacity: 1; /* Garante que os botões fiquem sempre visíveis */
  visibility: visible; /* Garante que os botões fiquem sempre visíveis */
}

/* Cor personalizada para o ícone de navegação */
.react-datepicker__navigation-icon::before {
  border-color: #121926; /* Altera a cor do ícone de navegação */
}

.react-datepicker__navigation--previous .react-datepicker__navigation-icon::before {
  border-right-color: #121926;
}

.react-datepicker__navigation--next .react-datepicker__navigation-icon::before {
  border-left-color: #121926;
}

.react-datepicker__navigation--previous:hover .react-datepicker__navigation-icon::before {
  border-right-color: #121926;
}

.react-datepicker__navigation--next:hover .react-datepicker__navigation-icon::before {
  border-left-color: #121926;
}