/* ExportModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    color: #121926;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.23);
  }

  .close-button:hover {
    color: #121926;
  }
  
  .modal-body {
    display: flex;
    justify-content: space-around;
  }
  
  .export-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #121926;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
  }
  
  .export-button:hover {
    background-color: #121926;
    color: white;
  }
  
  .export-button .icon {
    margin-bottom: 5px;
    font-size: 1.5rem;
  }

  .container-export-excel {
    border-radius: var(--borderRadius, 4px);
    display: flex;
    flex-direction: column;
  }

  .filter-email-export-excel {
    position: relative;
  }

  .filter-email-export-excel-text {
    background-color: white;
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6);
    padding: 5px 3px 4px;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    z-index: 1;
  }

  .email-input-export-excel {
    width: 500px;
    height: 40px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 12px;
    position: relative;
    margin-bottom: 18px;
    color: var(--text-primary, rgba(0, 0, 0, 0.87));
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .container-button-export-excel {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .generate-button-export-excel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #121926;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
  }

  .generate-button-export-excel:hover {
    background-color: #121926;
    color: white;
  }

  .sort-options-export-pdf {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    margin-top: 10px;
  }
  
  .custom-checkbox-export-pdf {
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
    font-size: 14px; 
    cursor: pointer;
    user-select: none;
    font-family: 'Arimo', sans-serif;
  }

  .custom-checkbox-export-pdf.title-and-subtitle {
    margin-bottom: 30px;
  }
  
  .custom-checkbox-export-pdf input {
    position: absolute;
    opacity: 0; 
    cursor: pointer;
  }
  
  .custom-checkbox-export-pdf .checkmark-export-pdf {
    width: 15px;
    height: 15px;
    background-color: #f0f0f0;
    border: 2px solid #ccc; 
    margin-right: 10px; 
    position: relative;
    display: inline-block;
    border-radius: 2px;
  }
  
  .custom-checkbox-export-pdf input:checked ~ .checkmark-export-pdf {
    background-color: #121926; 
    border-color: #121926;
  }
  
  .custom-checkbox-export-pdf .checkmark-export-pdf:after {
    content: "";
    position: absolute;
    display: none; 
  }
  
  .custom-checkbox-export-pdf input:checked ~ .checkmark-export-pdf:after {
    display: block; 
  }
  
  .custom-checkbox-export-pdf .checkmark-export-pdf:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .sort-title-export-pdf {
    margin: 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    color: #121926;
    margin-bottom: 15px;
  }

  .summary-add-export-pdf {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    margin-top: 10px;
  }

  .newsletter-input-section {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .generate-button-export-newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #121926;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid #121926;
    margin-left: 20px;
  }

  .generate-button-export-newsletter:hover {
    background-color: #121926;
    color: white;
  }

  .newsletter-input-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }