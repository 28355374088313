.container_avaliacao {
  background-color: #fff;
  display: flex;
  max-width: 600px; /* Ajustado para acomodar duas colunas */
  flex-direction: column;
  font-weight: 400;
  padding: 47px 50px;
  align-self: flex-end; /* Alinha o contêiner à direita */
  margin-left: auto; /* Move o contêiner para a direita */
}

@media (max-width: 991px) {
  .container_avaliacao {
    padding: 0 20px;
  }
}

.close-button_avaliacao {
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--Foundation-Blue-blue-900, #121926);
  align-self: end;
  width: 56px;
  color: #fff;
  height: 56px;
  padding: 0 4px;
  font: 600 24px/135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
  cursor: pointer;
}

@media (max-width: 991px) {
  .close-button_avaliacao {
    padding: 0 20px;
  }
}

.title_avaliacao {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font: 600 24px/135% IBM Plex Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .title_avaliacao {
    max-width: 100%;
  }
}

.hr {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.filters_container_avaliacao {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.filter-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.date-picker-filters-avaliacao {
  margin-bottom: 15px;
  position: relative;
}

.date-picker-label-filters-avaliacao {
  background-color: white;
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px 3px 4px;
  font-size: 10px;
  font-family: Roboto, sans-serif;
  z-index: 1;
}

.custom-select-tema {
  width: 95%;
  height: 50px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.radio-item_avaliacao {
  opacity: 0;
  position: absolute;
}

.feature_avaliacao {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 10px;
  margin-top: 20px;
}

.feature-label_avaliacao {
  display: inline-block;
  cursor: pointer;
}

.feature-label_avaliacao::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  margin-right: 5px;
  vertical-align: middle;
  margin-left: 12px;
  padding-left: 2px;
}

.radio-item_avaliacao:checked + .feature-label_avaliacao::before {
  content: '\2714';
  background-color: #000;
  color: #fff;
  font-size: 17px;
  padding-left: 2px;
}

.buttons_container_avaliacao {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.block-rate_avaliacao {
  font-family: Roboto, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  text-transform: uppercase;
  padding: 8px 22px;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
  margin-bottom: 10px;
  width: 90%;
  cursor: pointer;
}

.cancel-button_avaliacao {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
  width: 90%;
  cursor: pointer;
  height: 40px;
}

.cancel-button_avaliacao:hover {
  font-family: Roboto, sans-serif;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius, 4px);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background-color: var(--Foundation-Blue-blue-900, #121926);
  color: var(--primary-contrast, #fff);
  text-transform: uppercase;
  font: 500 15px/26px Roboto, sans-serif;
  font-size: 12px;
}

.feature-item_avaliacao {
  align-self: start;
  display: flex;
  gap: 9px;
  font-size: 16px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  padding-bottom: 15px;
}

.dropdown-avaliacao {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -20px;
}

.custom-select-avaliacao {
  width: 172px;
  height: 50px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.custom-select-valor {
  width: 150px;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  margin-bottom: 18px;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.evaluated-by-container {
  margin-top: 40px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 5px;
  border-left: 5px solid #121926;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.evaluator-by-name {
  font-weight: bold;
  color: #121926;
  background: #e3e7ed;
  padding: 5px 10px;
  border-radius: 5px;
}